import React from 'react'
import { Outlet } from 'react-router-dom'
import HomeNave from '../components/HomeNav/HomeNave'
import './Home/Home.scss'

export default function Layout() {
    return <>
        <div className="home-flex md:h-screen ">
            <HomeNave />
            <div className="flex flex-col w-full h-full overflow-x-hidden">
                <Outlet />
            </div>
        </div>

    </>
}
