import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Logo from '../../Assets/Images/New_logo_2.png'
import './Share.scss'

export default function ShareLayout() {
    return <>
        <nav className="ShareNav w-full flex flex-wrap justify-between items-center py-5 px-8" >
            <img src={Logo} alt="Logo" loading='lazy' width={"70"} height={"70"} />

            <Link to='/SignUp' className="text-white bg-[#636EFA] hover:bg-white hover:text-[#636EFA] border border-white
             focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 my-2 transition">
                Register now
            </Link>

        </nav>
        <Outlet />
    </>
}
