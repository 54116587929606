import React, { useContext, useState } from 'react'
import './HomeNave.scss'
import { LiaTableSolid } from "react-icons/lia";
import { CgChevronDoubleRightR } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";

import { FaBars } from "react-icons/fa";

import logo from '../../Assets/Images/New_logo_2.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import { ChartContext } from '../../Context/Charts';



export default function HomeNave() {
    const [Hide, setHide] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [, , removeCookie] = useCookies(['token']);
    const { TableId } = useContext(ChartContext)


    const Logout = () => {
        removeCookie('token', { path: '/' });
        navigate('/Login')
    }

    const handleHide = () => {
        setHide(!Hide)
    }
    return <>
        <div className={Hide ? "dropback apper-dropback" : "dropback "} onClick={handleHide}>
        </div>
        <aside className={Hide ? 'allnav  py-24 px-7' : 'allnav py-24 px-7 apper'} >

            <div>
                <img className="Logo-colaps" src={logo} loading='lazy' alt='Logo' />
                <div className="h-[300px] flex flex-col gap-y-1 mt-9">
                    <Link to='/' className={pathname === "/" ? 'Active' : ''}><IoHomeOutline className='icon' /></Link>
                    <Link to={`Tables/${TableId}`} className={pathname === `/Tables/${TableId}` ? 'Active' : ''}><LiaTableSolid className='icon' /></Link>
                    <Link to='Visualize' className={pathname === "/Visualize" ? 'Active' : ''}><CgChevronDoubleRightR className='icon' /></Link>
                    <Link to='Support' className={pathname === "/Support" ? 'Active' : ''}><BiSupport className='icon' /></Link>
                </div>

            </div>

            <RiLogoutBoxLine className='icon Logout cursor-pointer text-white' onClick={Logout} />

        </aside >

        <span className='bars' onClick={handleHide}>
            {Hide ? <RiCloseCircleLine /> : <FaBars />}
        </span>

    </>
}
