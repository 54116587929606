import React from 'react';
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom'

export default function LoginProtector(props) {
    const [cookies] = useCookies(["token"]);
    const token = cookies.token;
    if (token) {
        return props.children
    } else {
        return <Navigate to={"/Login"} />
    }
}
