import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import Charts from './Context/Charts';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools'
import '../node_modules/react-toastify/dist/ReactToastify.css';
import { Buffer } from 'buffer';
import process from 'process';

window.Buffer = Buffer;
window.process = process;

const root = ReactDOM.createRoot(document.getElementById('root'));

let query = new QueryClient();

root.render(
  <CookiesProvider defaultSetOptions={{ path: '/', secure: true }}>
    <QueryClientProvider client={query}>
      <Charts>
        <App />
        <ToastContainer position='bottom-right' />
      </Charts>
      {/* <ReactQueryDevtools position='bottom-right' /> */}
    </QueryClientProvider>
  </CookiesProvider>

);

reportWebVitals();
