import React, { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ChartContext } from '../Context/Charts';
import { toast } from 'react-toastify';
import { ToastOptions } from '../helpers/ToastOptions';

export default function DataIdProtector(props) {
    const { TableId } = useContext(ChartContext);

    useEffect(() => {
        if (!TableId) {
            toast.error("Please grab data first", ToastOptions("error"));
        }
    }, [TableId]);

    if (TableId) {
        return props.children;
    } else {
        return <Navigate to="/" />;
    }
}
